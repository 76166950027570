import { Box, createStyles } from '@asuikit/core'
import { useWeb3React } from '@web3-react/core'
import { useToggleAccountDrawer } from 'components/AccountDrawer/MiniPortfolio/hooks'
import { AutoColumn } from 'components/Column'
import PositionList from 'components/PositionList'
import { RowBetween } from 'components/Row'
import { useIsSupportedChainId } from 'constants/chains'
import { useNetworkSupportsV2 } from 'hooks/useNetworkSupportsV2'
import { useV3Positions } from 'hooks/useV3Positions'
import { Trans } from 'i18n'
import { createContext, useMemo, useState } from 'react'
import { AlertTriangle } from 'react-feather'
import { useUserHideClosedPositions } from 'state/user/hooks'
import styled, { css, useTheme } from 'styled-components'
import { ThemedText } from 'theme/components'
import { PositionDetails } from 'types/position'
import { LoadingRows } from './styled'

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    root: {
      width: '100%',
      // height: 640,
      overflow: 'auto',
      border: '1px solid ' + colors.line[1],
      background: colors.bg[1],
      borderRadius: 24,
      padding: '0 24px 24px',
      position: 'sticky',
      top: 96,
    },
    tag: {
      color: '#3C38F5',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      background: '#F1F1FE',
      padding: '2px 6px',
      borderRadius: 16,
    },

    label: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '16px',
      color: '#959AA4',
    },
    value: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '16px',
      color: '#4F5665',
    },
  }
})

const PageWrapper = styled(AutoColumn)`
  padding: 68px 8px 0px;
  max-width: 870px;
  width: 100%;

  @media (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    max-width: 800px;
    padding-top: 48px;
  }

  @media (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    max-width: 500px;
    padding-top: 20px;
  }
`
const TitleRow = styled(RowBetween)`
  color: ${({ theme }) => theme.neutral2};
  @media (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
  }
`

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 300px;
  min-height: 25vh;
`

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`

const NetworkIcon = styled(AlertTriangle)`
  ${IconStyle}
`

const MainContentWrapper = styled.main`
  background-color: ${({ theme }) => theme.surface1};
  border: 1px solid ${({ theme }) => theme.surface3};
  padding: 0;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export function PositionsLoadingPlaceholder() {
  return (
    <LoadingRows>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </LoadingRows>
  )
}

function WrongNetworkCard() {
  const theme = useTheme()

  return (
    <>
      <PageWrapper>
        <AutoColumn gap="lg" justify="center">
          <AutoColumn gap="lg" style={{ width: '100%' }}>
            <TitleRow padding="0">
              <ThemedText.H1Large>
                <Trans>Positions</Trans>
              </ThemedText.H1Large>
            </TitleRow>

            <MainContentWrapper>
              <ErrorContainer>
                <ThemedText.BodyPrimary color={theme.neutral3} textAlign="center">
                  <NetworkIcon strokeWidth={1.2} />
                  <div data-testid="pools-unsupported-err">
                    <Trans>Your connected network is unsupported.</Trans>
                  </div>
                </ThemedText.BodyPrimary>
              </ErrorContainer>
            </MainContentWrapper>
          </AutoColumn>
        </AutoColumn>
      </PageWrapper>
    </>
  )
}

type PositionsContextType = {
  refresh: () => void
}

export const PositionsContext = createContext<PositionsContextType>({
  refresh: () => undefined,
})

export default function Pool() {
  const { classes, cx } = useStyles()

  const { account, chainId } = useWeb3React()
  const isSupportedChain = useIsSupportedChainId(chainId)
  const networkSupportsV2 = useNetworkSupportsV2()
  const toggleWalletDrawer = useToggleAccountDrawer()

  const theme = useTheme()
  const [userHideClosedPositions, setUserHideClosedPositions] = useUserHideClosedPositions()

  const [getAccount, setGetAccount] = useState(account)

  const { positions, loading: positionsLoading } = useV3Positions(getAccount)

  function refresh() {
    setGetAccount(undefined)
    setTimeout(() => {
      setGetAccount(account)
    }, 1000)
  }

  const [openPositions, closedPositions] = positions?.reduce<[PositionDetails[], PositionDetails[]]>(
    (acc, p) => {
      acc[p.liquidity?.isZero() ? 1 : 0].push(p)
      return acc
    },
    [[], []]
  ) ?? [[], []]
  const userSelectedPositionSet = useMemo(
    () => [...openPositions, ...(userHideClosedPositions ? [] : closedPositions)],
    [closedPositions, openPositions, userHideClosedPositions]
  )
  // const filteredPositions = useFilterPossiblyMaliciousPositions(userSelectedPositionSet)

  if (!isSupportedChain) {
    return <WrongNetworkCard />
  }

  const showConnectAWallet = Boolean(!account)

  return (
    <>
      <PositionsContext.Provider
        value={{
          refresh,
        }}
      >
        <div className={classes.root}>
          <Box
            sx={({ colors }) => ({
              paddingTop: 24,
              position: 'sticky',
              top: 0,

              background: colors.bg[1],
            })}
          >
            {positionsLoading ? (
              <PositionsLoadingPlaceholder />
            ) : (
              <PositionList
                positions={userSelectedPositionSet}
                setUserHideClosedPositions={setUserHideClosedPositions}
                userHideClosedPositions={userHideClosedPositions}
              />
            )}
          </Box>
        </div>
      </PositionsContext.Provider>
    </>
  )
}
