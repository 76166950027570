import { Box, Collapse, createStyles, Group, Image, Stack, Text, TextInput } from '@asuikit/core'
import { useForm } from '@asuikit/form'
import { useDisclosure } from '@asuikit/hooks'
import type { MantineTheme } from '@asuikit/styles/lib/theme'
import { useWeb3React } from '@web3-react/core'
import { useRequest } from 'ahooks'
import BigNumber from 'bignumber.js'
import { merge } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri'
import { useLocation, useNavigate } from 'react-router-dom'
import ButtonCheckChain from '../../components/detrade/ButtonCheckChain'
import { useConnectionReady } from '../../connection/eagerlyConnect'
import { STATIC_BASEURL } from '../../constants/detrade'
import { useTokenBalancesQuery } from '../../graphql/data/apollo/TokenBalancesProvider'
import { useLargeThan } from '../../hooks/detrade/useWidthQuery'
import { useTokenBalances } from '../../hooks/useTokenBalances'
import { useNativeCurrencyBalances } from '../../lib/hooks/useCurrencyBalance'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { TransactionInfo, TransactionType } from '../../state/transactions/types'
import { sleep } from '../../utils'
import { mantissaNumber } from '../../utils/detrade/number'
import { NumberType, useFormatter } from '../../utils/formatNumbers'
import { handleErrorMessage } from '../../utils/handleErrorMessage'
import notification from '../../utils/notification'
import { createTokenChainId } from './createTokenConst'
import { useCreateToken } from './useCreateTokenContract'

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    root: {},
    blockBox: {
      border: '1px solid #000000',
      padding: 16,
      borderRadius: 8,
      '.title': {
        fontWeight: 500,
        fontSize: 18,
        lineHeight: '26px',
        color: '#0B132A',
      },
      '.content': {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '24px',
        color: '#4F5665',
      },
    },
  }
})

function getCheckboxStyle(theme: MantineTheme) {
  return {
    body: {
      alignItems: 'center',
    },
    inner: {
      width: 16,
      height: 16,
      input: {
        width: 16,
        height: 16,
      },
    },
    label: {
      paddingLeft: 4,
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
      color: '#0B132A',
    },
  }
}
const MAX_INTEGER = '1' + '0'.repeat(52)

function getInputStyle(theme: MantineTheme) {
  return {
    wrapper: {
      border: '1px solid #BABDC466',
      borderRadius: 8,
    },
    label: {
      fontWeight: 600,
      fontSize: 18,
      lineHeight: '24px',
      color: '#0B132A',
      marginBottom: 10,
    },
    input: {
      border: 0,
      background: '#F0F0F0',
      borderRadius: 8,
      height: 48,
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
      color: '#0B132A',
    },
  }
}
const MAX_VALUE_STRING = '1' + '0'.repeat(52) // 10^52 as string
const MAX_VALUE = BigInt(MAX_VALUE_STRING) // 10^52 as BigInt
const isValidNumber = (value: string) => {
  // Regular expression to match a valid number with up to 18 decimal places
  const regex = /^\d*$/
  return regex.test(value)
}

export default function CreateTokenForm({ onSuccess }: { onSuccess?: () => void }) {
  const { classes } = useStyles()
  const smScreen = useLargeThan('sm')
  const mdScreen = useLargeThan('md')
  const location = useLocation()

  const connectionReady = useConnectionReady()
  const { account, provider, chainId: connectedChainId, connector } = useWeb3React()
  const handleChange = (e: any) => {
    let newValue = e.target.value

    // Validate the new value format
    if (isValidNumber(newValue)) {
      // Convert to BigInt for comparison if necessary
      const [integerPart] = newValue.split('.')
      const integerPartBigInt = BigInt(integerPart || '0')

      // Check if the integer part exceeds the maximum allowed value
      if (integerPartBigInt > MAX_VALUE) {
        newValue = MAX_VALUE_STRING
      }
      e.target.value = newValue
    } else {
      e.target.value = form.values.maxSupply || ''
    }
    form.getInputProps('maxSupply').onChange(e)
  }
  const navigate = useNavigate()
  const form = useForm({
    initialValues: {
      name: '',
      symbol: '',
      maxSupply: '',
      tradeBurnRatio: '',
      tradeFeeRatio: '',
    },

    validate: {
      // name: (value) => (/^.{1,64}$/.test(value) ? null : 'Invalid Token Name'),
      symbol: (value) => (/^.{1,16}$/.test(value) ? null : 'Invalid Token Symbol'),
      maxSupply: (value) => (/\d+$/.test(String(value)) ? null : 'Invalid Token Supply'),
    },
  })

  const [opened, { toggle }] = useDisclosure(false)

  const [showBrun, setShowBrun] = useState(false)
  const [showFee, setShowFee] = useState(false)
  useEffect(() => {
    if (!showBrun) {
      form.setFieldValue('tradeBurnRatio', '')
    }
  }, [showBrun])
  useEffect(() => {
    if (!showFee) {
      form.setFieldValue('tradeFeeRatio', '')
    }
  }, [showFee])

  const addTransaction = useTransactionAdder()
  const createToken = useCreateToken()
  const { data } = useTokenBalancesQuery()

  const { balanceMap } = useTokenBalances()
  const ary = useNativeCurrencyBalances([account])

  const { formatCurrencyAmount } = useFormatter()
  const balance = useMemo(() => {
    if (!account || !ary?.[account]) return 0
    const res = formatCurrencyAmount({
      amount: ary?.[account],
      type: NumberType.TokenNonTx,
    })
    return Number(res || 0)
  }, [account, ary, formatCurrencyAmount])

  const { run, loading } = useRequest(
    async (values) => {
      if (balance <= 0.002) {
        return notification.warn(`Insufficient balance`)
      }
      try {
        BigNumber.config({ EXPONENTIAL_AT: 1e9 })
        const maxSupply = BigNumber(values.maxSupply).multipliedBy(1e18)
        const tradeBurnRatio = Number(values.tradeBurnRatio || 0) * 1e4
        const tradeFeeRatio = Number(values.tradeFeeRatio || 0) * 1e4
        const params = {
          name: values.name || values.symbol,
          symbol: values.symbol,
          maxSupply: maxSupply.toString(),
          tradeBurnRatio: BigInt(tradeBurnRatio),
          tradeFeeRatio: BigInt(tradeFeeRatio),
        }
        console.log(values, params, maxSupply)
        const response = await createToken(params)
        if (!response) return
        const transactionInfo: TransactionInfo = {
          type: TransactionType.CREATE_TOKEN,
          data: {
            detail: values,
          },
        }
        addTransaction(response, transactionInfo)
        await provider?.waitForTransaction(response.hash)
        if (onSuccess) {
          await sleep(5000)
          onSuccess()
        }
        form.reset()
        notification.success(`Token Created!`)
      } catch (e: any) {
        console.log(e)
        handleErrorMessage(e)
      }
    },
    {
      manual: true,
    }
  )
  const canSubmit = useMemo(() => {
    return !!form.values.symbol && !!form.values.maxSupply
  }, [form.values.maxSupply, form.values.symbol])
  return (
    <Box>
      <form onSubmit={form.onSubmit((values) => run(values))}>
        <Image
          width="100%"
          height="auto"
          src={`${STATIC_BASEURL}/images/detrade/create-token/banner.svg`}
          sx={({ colors }) => ({
            position: 'relative',
            zIndex: 1,
          })}
        />
        <Box
          sx={({ colors }) => ({
            width: '100%',
            border: '1px solid #000000',
            borderRadius: 16,
            marginTop: -14,
            zIndex: 9,
            position: 'relative',
            backgroundColor: '#FFFFFF',
          })}
        >
          <Box p={40}>
            <Box mx="auto" maw={424} sx={{ width: '100%', height: '100%' }}>
              <Group position="apart">
                <Text fw={700} fz={24} lh="32px" color="#0B132A">
                  Enter Token Parameters
                </Text>
                <Text fw={400} fz={16} lh="20px" color="#4F5665">
                  <Group spacing={0} sx={{ cursor: 'pointer' }} onClick={toggle}>
                    More
                    {opened ? <RiArrowUpSLine size={14} /> : <RiArrowDownSLine size={14} />}
                  </Group>
                </Text>
              </Group>
              <Stack spacing={24} mt={16}>
                <TextInput
                  label="Token Symbol"
                  maxLength={11}
                  placeholder="1-11 characters"
                  {...form.getInputProps('symbol')}
                  styles={(theme) => {
                    return merge(getInputStyle(theme), {})
                  }}
                />
                <TextInput
                  label="Token Supply"
                  placeholder="99,999,999,999,999,999"
                  {...form.getInputProps('maxSupply')}
                  styles={(theme) => {
                    return merge(getInputStyle(theme), {})
                  }}
                  value={form.getInputProps('maxSupply').value}
                  onChange={handleChange}
                />

                <Collapse in={opened}>
                  <Stack spacing={24}>
                    <TextInput
                      label="Token Name"
                      placeholder="1-64 characters"
                      maxLength={64}
                      {...form.getInputProps('name')}
                      styles={(theme) => {
                        return merge(getInputStyle(theme), {})
                      }}
                    />
                    {/*<NumberInput*/}
                    {/*  label="Decimals"*/}
                    {/*  min={1}*/}
                    {/*  max={18}*/}
                    {/*  hideControls*/}
                    {/*  placeholder="0-18"*/}
                    {/*  value={18}*/}
                    {/*  disabled*/}
                    {/*  styles={(theme) => {*/}
                    {/*    return merge(getInputStyle(theme), {})*/}
                    {/*  }}*/}
                    {/*/>*/}
                  </Stack>
                </Collapse>
              </Stack>
              {/*<Stack mt={40} spacing={16}>*/}
              {/*  <Text fw={700} fz={24} lh="32px" color="#3C38F5">*/}
              {/*    Special Features*/}
              {/*  </Text>*/}

              {/*  <Group spacing={24}>*/}
              {/*    <Checkbox*/}
              {/*      label="Burn"*/}
              {/*      styles={(theme) => getCheckboxStyle(theme)}*/}
              {/*      checked={showBrun}*/}
              {/*      onChange={(event) => setShowBrun(event.currentTarget.checked)}*/}
              {/*    />*/}
              {/*    <Checkbox*/}
              {/*      label="Trading Fees"*/}
              {/*      styles={(theme) => getCheckboxStyle(theme)}*/}
              {/*      checked={showFee}*/}
              {/*      onChange={(event) => setShowFee(event.currentTarget.checked)}*/}
              {/*    />*/}
              {/*  </Group>*/}

              {/*  <Collapse in={showBrun}>*/}
              {/*    <Box className={classes.blockBox}>*/}
              {/*      <Group noWrap spacing={20}>*/}
              {/*        <Stack spacing={6} sx={{ flex: 1 }}>*/}
              {/*          <div className="title">Burn:</div>*/}
              {/*          <div className="content">*/}
              {/*            A portion of tokens will be sent to the burn address with each on-chain transfer.*/}
              {/*          </div>*/}
              {/*        </Stack>*/}
              {/*        <NumberInput*/}
              {/*          removeTrailingZeros*/}
              {/*          placeholder="0"*/}
              {/*          precision={2}*/}
              {/*          min={0}*/}
              {/*          max={50}*/}
              {/*          rightSection={*/}
              {/*            <Text fw={700} fz={16} lh="24px" pr={10}>*/}
              {/*              %*/}
              {/*            </Text>*/}
              {/*          }*/}
              {/*          styles={(theme) => {*/}
              {/*            return merge(getInputStyle(theme), {*/}
              {/*              wrapper: {*/}
              {/*                width: 132,*/}
              {/*              },*/}
              {/*            })*/}
              {/*          }}*/}
              {/*          {...form.getInputProps('tradeBurnRatio')}*/}
              {/*        />*/}
              {/*      </Group>*/}
              {/*    </Box>*/}
              {/*  </Collapse>*/}

              {/*  <Collapse in={showFee}>*/}
              {/*    <Box className={classes.blockBox}>*/}
              {/*      <Group noWrap spacing={20}>*/}
              {/*        <Stack spacing={6} sx={{ flex: 1 }}>*/}
              {/*          <div className="title">Trading Fees:</div>*/}
              {/*          <div className="content">*/}
              {/*            {`For each on-chain transfer, a portion of the tokens will be sent to the creator's address.`}*/}
              {/*          </div>*/}
              {/*        </Stack>*/}
              {/*        <NumberInput*/}
              {/*          removeTrailingZeros*/}
              {/*          placeholder="0"*/}
              {/*          precision={2}*/}
              {/*          min={0}*/}
              {/*          max={50}*/}
              {/*          rightSection={*/}
              {/*            <Text fw={700} fz={16} lh="24px" pr={10}>*/}
              {/*              %*/}
              {/*            </Text>*/}
              {/*          }*/}
              {/*          styles={(theme) => {*/}
              {/*            return merge(getInputStyle(theme), {*/}
              {/*              wrapper: {*/}
              {/*                width: 132,*/}
              {/*              },*/}
              {/*            })*/}
              {/*          }}*/}
              {/*          {...form.getInputProps('tradeFeeRatio')}*/}
              {/*        />*/}
              {/*      </Group>*/}
              {/*    </Box>*/}
              {/*  </Collapse>*/}
              {/*</Stack>*/}
            </Box>
          </Box>
          <Group position="apart" py={16} px={32} sx={{ borderTop: '1px solid #000000' }}>
            <Text fw={600} fz={24} lh="31px" color="#0B132A">
              Service Fees{' '}
              <Text span color="#3C38F5">
                {mantissaNumber(0.002)}
              </Text>{' '}
              ETH
            </Text>

            <ButtonCheckChain
              size="xl"
              miw={193}
              type="submit"
              disabled={!canSubmit}
              loading={loading}
              chainId={createTokenChainId}
            >
              Create a token
            </ButtonCheckChain>
          </Group>
        </Box>
      </form>
    </Box>
  )
}
