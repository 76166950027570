import { Box, Button, Center, Checkbox, Divider, Group, Image, Stack, Text } from '@asuikit/core'
import { useWeb3React } from '@web3-react/core'
import PositionListItem from 'components/PositionListItem'
import React from 'react'
import { PositionDetails } from 'types/position'
import { STATIC_BASEURL } from '../../constants/detrade'
import { Trans } from '../../i18n'
import { useToggleAccountDrawer } from '../AccountDrawer/MiniPortfolio/hooks'

type PositionListProps = React.PropsWithChildren<{
  positions: PositionDetails[]
  setUserHideClosedPositions: any
  userHideClosedPositions: boolean
}>

export function PositionListEmpty() {
  const { account, chainId } = useWeb3React()
  const showConnectAWallet = Boolean(!account)
  const toggleWalletDrawer = useToggleAccountDrawer()
  return (
    <Box mt={158} pb={180}>
      <Center>
        <Image width={204} height={126} src={`${STATIC_BASEURL}/images/detrade/liquidity/empty-position-img.svg`} />
      </Center>

      <Center mt={8}>
        <Text fw={500} fz={16} lh="20px" color="#0B132A">
          Your active liquidity positions wilappear here.
        </Text>
      </Center>
      {showConnectAWallet && (
        <Center mt={24} onClick={toggleWalletDrawer}>
          <Button size="lg">
            <Trans>Connect Wallet</Trans>
          </Button>
        </Center>
      )}
    </Box>
  )
}

export default function PositionList({
  positions,
  setUserHideClosedPositions,
  userHideClosedPositions,
}: PositionListProps) {
  const { account, chainId } = useWeb3React()

  const showConnectAWallet = Boolean(!account)
  const toggleWalletDrawer = useToggleAccountDrawer()

  return (
    <>
      <Group position="apart">
        <Text fw={500} fz={20} lh="24px" color="#0B132A">
          Positions{positions && ' (' + positions.length + ')'}
        </Text>
        <Checkbox
          size={16}
          label="Hide closed position"
          checked={userHideClosedPositions}
          onChange={() => {
            setUserHideClosedPositions(!userHideClosedPositions)
          }}
          styles={({ colors }) => ({
            body: {
              alignItems: 'center',
            },
            label: {
              paddingLeft: 4,
              fontWeight: 400,
              fontSize: 16,
              lineHeight: '20px',
            },
          })}
        />
      </Group>
      <Divider mt={27} mb={16} color="line.1" />
      {/*<MobileHeader>*/}
      {/*  <Trans>Your positions</Trans>*/}
      {/*  <ToggleWrap>*/}
      {/*    <ToggleLabel*/}
      {/*      onClick={() => {*/}
      {/*        setUserHideClosedPositions(!userHideClosedPositions)*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      {userHideClosedPositions ? <Trans>Show closed positions</Trans> : <Trans>Hide closed positions</Trans>}*/}
      {/*    </ToggleLabel>*/}
      {/*  </ToggleWrap>*/}
      {/*</MobileHeader>*/}

      {positions && positions.length > 0 ? (
        <Stack spacing={0}>
          {positions.map((p) => (
            <PositionListItem key={p.tokenId.toString()} {...p} />
          ))}
        </Stack>
      ) : (
        <PositionListEmpty />
      )}
    </>
  )
}
