import { Box, Button, Checkbox, createStyles, Group, Modal, Stack, Text } from '@asuikit/core'
import { useDisclosure } from '@asuikit/hooks'
import type { MantineTheme } from '@asuikit/styles/lib/theme'
import { useWeb3React } from '@web3-react/core'
import { useRequest } from 'ahooks'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { CreateTokenItem } from 'service/createToken.api.d'
import { useConnectionReady } from '../../connection/eagerlyConnect'
import { useLargeThan } from '../../hooks/detrade/useWidthQuery'
import { sleep } from '../../utils'
import { handleErrorMessage } from '../../utils/handleErrorMessage'
import isZero from '../../utils/isZero'
import notification from '../../utils/notification'
import { useContractOwner, useTransferOwnership } from './useCreateTokenContract'

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    root: {},
    block: {
      borderRadius: 12,
      background: colors.bg[3],
      padding: 16,
      '.title': {
        marginBottom: 4,
        fontWeight: 600,
        fontSize: 20,
        lineHeight: '28px',
        color: '#0B132A',
      },
      '.content': {
        marginBottom: 8,
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '20px',
        color: '#4F5665',
      },
    },
  }
})

function getModalStyle(theme: MantineTheme) {
  const { colors } = theme
  return {
    content: {
      background: colors.bg[1],
    },
    header: {
      background: colors.bg[1],
      padding: '24px 24px 0',
      // borderBottom: '1px solid' + colors.line[2],
    },
    close: {
      width: 24,
      height: 24,
      border: '1px solid #CCCCCC',
      borderRadius: 6,
    },
    title: {
      fontWeight: 500,
      fontSize: 20,
      lineHeight: '24px',
      color: '#0B132A',
    },
    body: {
      padding: '16px 24px 24px',
    },
  }
}

export default function ModalManage({
  item,
  opened,
  onClose,
  onRefresh,
}: {
  opened: boolean
  onClose: () => void
  onRefresh: () => void
  item: CreateTokenItem
}) {
  const { classes } = useStyles()
  const smScreen = useLargeThan('sm')
  const mdScreen = useLargeThan('md')
  const location = useLocation()

  const connectionReady = useConnectionReady()
  const { account, chainId: connectedChainId, connector } = useWeb3React()

  const [openedConfirm, { open: openConfirm, close: closeConfirm }] = useDisclosure(false)
  const [isConfirm, setIsConfirm] = useState(false)
  useEffect(() => {
    if (!openedConfirm) {
      setIsConfirm(false)
    }
  }, [openedConfirm])
  useEffect(() => {
    if (!opened) {
      closeConfirm()
    }
  }, [opened])

  const transferOwnership = useTransferOwnership(item.token_address)

  const { data: owner, refresh } = useContractOwner(item.token_address || '')

  const { loading, run } = useRequest(
    async () => {
      try {
        await transferOwnership()
        await sleep(2000)
        await refresh()
        if (onRefresh) {
          onRefresh()
        }
        notification.success('Success!')
        onClose()
      } catch (e: any) {
        console.log(e)
        handleErrorMessage(e)
      }
    },
    {
      manual: true,
    }
  )
  if (!item) return null

  return (
    <>
      <Modal
        size={480}
        title="Manage"
        opened={opened && !openedConfirm}
        onClose={onClose}
        centered
        styles={getModalStyle}
      >
        <Stack spacing={16} mt={16}>
          {owner && !isZero(owner) && (
            <Box className={classes.block}>
              <div className="title">Relinquish Ownership</div>
              <div className="content">
                Relinquish token ownerships to enable further decentralization of our community
              </div>
              <Button fullWidth size="lg" onClick={openConfirm}>
                Execute
              </Button>
            </Box>
          )}

          <Box className={classes.block}>
            <div className="title">Create Pool</div>
            <div className="content">You and the community can add funds into the pool to provide liquidity</div>
            <Link to={`/add/v2/ETH/${item.token_address}`}>
              <Button fullWidth size="lg">
                GO
              </Button>
            </Link>
          </Box>
        </Stack>
      </Modal>

      <Modal
        size={480}
        closeOnClickOutside={!loading}
        withCloseButton={!loading}
        title="Execute"
        opened={openedConfirm}
        onClose={closeConfirm}
        centered
        styles={getModalStyle}
      >
        <Stack spacing={20} mt={16}>
          <Stack spacing={8}>
            <Text fw={600} fz={24} lh="28px" color="#0B132A">
              Are you sure you want to give up the ownership?
            </Text>
            <Text fw={400} fz={14} lh="20px" color="#4F5665">
              You can relinquish the ownership of your token by setting its owner address to a void address. This change
              cannot be reversed once confirmed.
            </Text>
            <Checkbox
              checked={isConfirm}
              onChange={(event) => setIsConfirm(event.currentTarget.checked)}
              label="Yes, I am sure I want to give up the ownership."
              styles={(theme) => {
                return {
                  body: {
                    alignItems: 'center',
                  },
                  inner: {
                    width: 16,
                    height: 16,
                    input: {
                      width: 16,
                      height: 16,
                    },
                  },
                  label: {
                    paddingLeft: 4,
                    fontWeight: 500,
                    fontSize: 16,
                    lineHeight: '24px',
                    color: '#0B132A',
                  },
                }
              }}
            />
          </Stack>
          <Group noWrap spacing={8}>
            <Button
              fullWidth
              size="xl"
              sx={({ colors }) => ({
                background: '#F1F1FE',
                color: '#3C38F5',
                flex: '1 0',
                ':hover': {
                  color: '#fff',
                },
              })}
              onClick={closeConfirm}
            >
              Cancle
            </Button>
            <Button fullWidth size="xl" sx={{ flex: '1 0' }} disabled={!isConfirm} loading={loading} onClick={run}>
              Confirm
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  )
}
