import { Box, Divider, Group, Image, Stack, Text } from '@asuikit/core'
import { useLocation, useNavigate } from 'react-router-dom'
import { LPDBannerModel } from 'service/launch.api.d'
import { useLargeThan } from '../../hooks/detrade/useWidthQuery'
import LaunchTimeCountdown from './LaunchTimeCountdown'

export default function LaunchBanner({ item }: { item: LPDBannerModel }) {
  const smScreen = useLargeThan('sm')
  const mdScreen = useLargeThan('md')
  const location = useLocation()

  const navigate = useNavigate()

  const startTime = item.project_start_time * 1000
  const endTime = item.project_end_time * 1000
  return (
    <Box
      onClick={() => {
        navigate(`/launch/${item.project_id}`)
      }}
      sx={({ colors }) => ({
        width: '100%',
        height: 320,
        backgroundImage: `url(${item.project_banner})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'auto 100%',
        borderRadius: 16,
        overflow: 'hidden',
        padding: '56px 48px 24px',
        position: 'relative',
        cursor: 'pointer',
      })}
    >
      <Stack
        h="100%"
        justify="space-between"
        sx={({ colors }) => ({
          position: 'relative',
          zIndex: 9,
        })}
      >
        <Text
          fw={600}
          fz={40}
          lh="48px"
          color="#fff"
          // maw={631}
          sx={{ whiteSpace: 'pre-line' }}
        >
          {item.project_banner_desc}
        </Text>

        <Group spacing={8}>
          <Image width={40} height={40} src={item.project_logo} radius={40} />

          <Group spacing={12}>
            <Text fw={600} fz={18} lh="20px" color="#fff">
              {item.project_name}
            </Text>
            <Divider orientation="vertical" h={20} color="#FFFFFF47" sx={{ alignSelf: 'center' }} />
            <Text fw={500} fz={16} lh="20px" color="#FE9839">
              <LaunchTimeCountdown startTime={startTime} endTime={endTime} />
            </Text>
          </Group>
        </Group>
      </Stack>
      <Box
        sx={({ colors }) => ({
          width: '100%',
          height: 120,
          position: 'absolute',
          left: 0,
          bottom: 0,
          zIndex: 0,
          background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)',
        })}
      ></Box>
    </Box>
  )
}
