import { ChainId, Currency } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import Badge from 'components/Badge'
import { CHAIN_INFO, SupportedL2ChainId, useIsSupportedChainId } from 'constants/chains'
import { Trans, t } from 'i18n'
import { ReactNode, useCallback, useState } from 'react'
import { ArrowUpCircle, CheckCircle } from 'react-feather'
import { useIsTransactionConfirmed, useTransaction } from 'state/transactions/hooks'
import styled, { useTheme } from 'styled-components'
import { CustomLightSpinner, ExternalLink, ThemedText } from 'theme/components'
import { isL2ChainId } from 'utils/chains'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'

import { Button, Modal, Stack, Text } from '@asuikit/core'
import { useCurrencyInfo } from 'hooks/Tokens'
import { RiCheckDoubleFill, RiCloseCircleLine, RiLoader4Fill } from 'react-icons/ri'
import Circle from '../../assets/images/blue-loader.svg'
import { TransactionSummary } from '../AccountDetails/TransactionSummary'
import { ButtonLight, ButtonPrimary } from '../Button'
import { AutoColumn, ColumnCenter } from '../Column'
import Row, { RowFixed } from '../Row'

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.surface1};
  border-radius: 20px;
  outline: 1px solid ${({ theme }) => theme.surface3};
  width: 100%;
  padding: 16px;
`

const BottomSection = styled(AutoColumn)`
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`

const ConfirmedIcon = styled(ColumnCenter)<{ inline?: boolean }>`
  padding: ${({ inline }) => (inline ? '20px 0' : '32px 0;')};
`

const ConfirmationModalContentWrapper = styled(AutoColumn)`
  padding-bottom: 12px;
`

export function ConfirmationPendingContent({
  onDismiss,
  pendingText,
  inline,
}: {
  onDismiss: () => void
  pendingText: ReactNode
  inline?: boolean // not in modal
}) {
  return (
    <>
      <AutoColumn gap="md">
        {/*{!inline && (*/}
        {/*  <RowBetween>*/}
        {/*    <div />*/}
        {/*    <CloseIcon onClick={onDismiss} />*/}
        {/*  </RowBetween>*/}
        {/*)}*/}
        <ConfirmedIcon inline={inline}>
          <CustomLightSpinner src={Circle} alt="loader" size={inline ? '40px' : '90px'} />
        </ConfirmedIcon>
        <AutoColumn gap="md" justify="center">
          <ThemedText.SubHeaderLarge color="neutral1" textAlign="center">
            <Trans>Waiting for confirmation</Trans>
          </ThemedText.SubHeaderLarge>
          <ThemedText.SubHeader color="neutral1" textAlign="center">
            {pendingText}
          </ThemedText.SubHeader>
          <ThemedText.SubHeaderSmall color="neutral2" textAlign="center" marginBottom="12px">
            <Trans>Confirm this transaction in your wallet</Trans>
          </ThemedText.SubHeaderSmall>
        </AutoColumn>
      </AutoColumn>
    </>
  )
}

function TransactionSubmittedContent({
  onDismiss,
  chainId,
  hash,
  currencyToAdd,
  inline,
}: {
  onDismiss: () => void
  hash?: string
  chainId: number
  currencyToAdd?: Currency
  inline?: boolean // not in modal
}) {
  const theme = useTheme()

  const { connector } = useWeb3React()

  const token = currencyToAdd?.wrapped
  const logoURL = useCurrencyInfo(token)?.logoUrl ?? ''

  const [success, setSuccess] = useState<boolean | undefined>()

  const addToken = useCallback(() => {
    if (!token?.symbol || !connector.watchAsset) return
    connector
      .watchAsset({
        address: token.address,
        symbol: token.symbol,
        decimals: token.decimals,
        image: logoURL,
      })
      .then(() => setSuccess(true))
      .catch(() => setSuccess(false))
  }, [connector, logoURL, token])

  const explorerText = chainId === ChainId.MAINNET ? t`View on  Etherscan` : t`View on Block Explorer`

  return (
    <>
      <AutoColumn>
        {/*{!inline && (*/}
        {/*  <RowBetween>*/}
        {/*    <div />*/}
        {/*    <CloseIcon onClick={onDismiss} />*/}
        {/*  </RowBetween>*/}
        {/*)}*/}
        <ConfirmedIcon inline={inline}>
          <ArrowUpCircle strokeWidth={1} size={inline ? '40px' : '75px'} color={theme.accent1} />
        </ConfirmedIcon>
        <ConfirmationModalContentWrapper gap="md" justify="center">
          <ThemedText.MediumHeader textAlign="center">
            <Trans>Transaction submitted</Trans>
          </ThemedText.MediumHeader>
          {currencyToAdd && connector.watchAsset && (
            <ButtonLight mt="12px" padding="6px 12px" width="fit-content" onClick={addToken}>
              {!success ? (
                <RowFixed>
                  <Trans>Add {{ sym: currencyToAdd.symbol }}</Trans>
                </RowFixed>
              ) : (
                <RowFixed>
                  <Trans>Added {{ sym: currencyToAdd.symbol }} </Trans>
                  <CheckCircle size="16px" stroke={theme.success} style={{ marginLeft: '6px' }} />
                </RowFixed>
              )}
            </ButtonLight>
          )}
          <ButtonPrimary onClick={onDismiss} style={{ margin: '20px 0 0 0' }} data-testid="dismiss-tx-confirmation">
            <ThemedText.HeadlineSmall color={theme.deprecated_accentTextLightPrimary}>
              {inline ? <Trans>Return</Trans> : <Trans>Close</Trans>}
            </ThemedText.HeadlineSmall>
          </ButtonPrimary>
          {chainId && hash && (
            <ExternalLink href={getExplorerLink(chainId, hash, ExplorerDataType.TRANSACTION)}>
              <ThemedText.Link color={theme.accent1}>{explorerText}</ThemedText.Link>
            </ExternalLink>
          )}
        </ConfirmationModalContentWrapper>
      </AutoColumn>
    </>
  )
}

export function ConfirmationModalContent({
  title,
  bottomContent,
  onDismiss,
  topContent,
  headerContent,
}: {
  title?: ReactNode
  onDismiss: () => void
  topContent: () => ReactNode
  bottomContent?: () => ReactNode
  headerContent?: () => ReactNode
}) {
  return (
    <>
      <>
        <Stack spacing={16}>
          {headerContent?.()}

          {title && (
            <Row justify="center" marginLeft="24px">
              <ThemedText.SubHeader>{title}</ThemedText.SubHeader>
            </Row>
          )}

          {topContent()}
        </Stack>
      </>
      {bottomContent && bottomContent()}
    </>
  )
}

const StyledL2Badge = styled(Badge)`
  padding: 6px 8px;
`

function L2Content({
  onDismiss,
  chainId,
  hash,
  pendingText,
  inline,
  handlerContent,
}: {
  onDismiss: () => void
  hash?: string
  chainId: SupportedL2ChainId
  currencyToAdd?: Currency
  pendingText: ReactNode
  inline?: boolean // not in modal
  handlerContent?: () => ReactNode
}) {
  const theme = useTheme()

  const transaction = useTransaction(hash)
  const confirmed = useIsTransactionConfirmed(hash)
  const transactionSuccess = transaction?.receipt?.status === 1

  // convert unix time difference to seconds
  const secondsToConfirm = transaction?.confirmedTime
    ? (transaction.confirmedTime - transaction.addedTime) / 1000
    : undefined

  const info = CHAIN_INFO[chainId]

  return (
    <>
      {/*{!inline && (*/}
      {/*  <RowBetween mb="16px">*/}
      {/*    <StyledL2Badge>*/}
      {/*      <RowFixed gap="sm">*/}
      {/*        <ChainLogo chainId={chainId} />*/}
      {/*        <ThemedText.SubHeaderSmall>{info.label}</ThemedText.SubHeaderSmall>*/}
      {/*      </RowFixed>*/}
      {/*    </StyledL2Badge>*/}
      {/*    <CloseIcon onClick={onDismiss} />*/}
      {/*  </RowBetween>*/}
      {/*)}*/}
      <Stack spacing={8} align="center" w="100%" mt={24}>
        {confirmed ? (
          transactionSuccess ? (
            // <CheckCircle strokeWidth={1} size={inline ? '40px' : '90px'} color={theme.success} />
            <RiCheckDoubleFill color="#45AF5C" size={60} />
          ) : (
            <RiCloseCircleLine color="#FE9839" size={60} />
          )
        ) : (
          <RiLoader4Fill color="#3C38F5" size={60} className="animate-spin" />
        )}

        <Text fw={500} fz={24} lh="32px" color="#0B132A">
          {!hash ? (
            <Trans>Confirm transaction in wallet</Trans>
          ) : !confirmed ? (
            <Trans>Transaction submitted</Trans>
          ) : transactionSuccess ? (
            <Trans>Success</Trans>
          ) : (
            <Trans>Error</Trans>
          )}
        </Text>
        <Text fw={400} fz={16} lh="20px" color="#959AA4">
          {transaction ? <TransactionSummary info={transaction.info} /> : pendingText}
        </Text>
        {chainId && hash && (
          <Text fw={400} fz={16} lh="20px" color="#3C38F5">
            <ExternalLink href={getExplorerLink(chainId, hash, ExplorerDataType.TRANSACTION)}>
              <Trans>View on Explorer</Trans>
            </ExternalLink>
          </Text>
        )}
        {secondsToConfirm && (
          <ThemedText.SubHeaderSmall color={theme.neutral3}>
            <Trans>Transaction completed in </Trans>
            <span style={{ fontWeight: 535, marginLeft: '4px', color: theme.neutral1 }}>
              {secondsToConfirm} seconds 🎉
            </span>
          </ThemedText.SubHeaderSmall>
        )}
      </Stack>

      <Stack spacing={12} mt={40}>
        {handlerContent && handlerContent()}
        {handlerContent ? (
          <Button
            variant="default"
            bg="#F1F1FE"
            onClick={onDismiss}
            fullWidth
            size="xl"
            sx={({ colors }) => ({
              border: 0,
              color: colors.blue[9],
            })}
          >
            {inline ? <Trans>Return</Trans> : <Trans>Close</Trans>}
          </Button>
        ) : (
          <Button onClick={onDismiss} fullWidth size="xl">
            {inline ? <Trans>Return</Trans> : <Trans>Close</Trans>}
          </Button>
        )}
      </Stack>
    </>
  )
}

interface ConfirmationModalProps {
  isOpen: boolean
  onDismiss: () => void
  hash?: string
  title?: string | ReactNode
  reviewContent: () => ReactNode
  handlerContent?: () => ReactNode
  attemptingTxn: boolean
  pendingText: ReactNode
  currencyToAdd?: Currency
}

export default function TransactionConfirmationModal({
  isOpen,
  onDismiss,
  attemptingTxn,
  hash,
  pendingText,
  reviewContent,
  handlerContent,
  currencyToAdd,
  title = 'Liquidity',
}: ConfirmationModalProps) {
  const { chainId } = useWeb3React()
  const isSupportedChain = useIsSupportedChainId(chainId)

  if (!chainId || !isSupportedChain) return null
  // confirmation screen
  return (
    <Modal
      size={480}
      title={title}
      opened={isOpen}
      onClose={onDismiss}
      centered
      styles={({ colors, fn }) => ({
        content: {
          background: colors.bg[1],
        },
        header: {
          background: colors.bg[1],
          height: 64,
          padding: '20px 24px',
          // borderBottom: '1px solid' + colors.line[2],
        },
        title: {
          fontWeight: 500,
          fontSize: 20,
          lineHeight: '24px',
          color: '#0B132A',
        },
        body: {
          padding: '8px 24px 24px',
        },
      })}
    >
      {isL2ChainId(chainId) && (hash || attemptingTxn) ? (
        <L2Content
          chainId={chainId}
          hash={hash}
          onDismiss={onDismiss}
          pendingText={pendingText}
          handlerContent={handlerContent}
        />
      ) : attemptingTxn ? (
        <ConfirmationPendingContent onDismiss={onDismiss} pendingText={pendingText} />
      ) : hash ? (
        <TransactionSubmittedContent
          chainId={chainId}
          hash={hash}
          onDismiss={onDismiss}
          currencyToAdd={currencyToAdd}
        />
      ) : (
        reviewContent()
      )}
    </Modal>
  )
}
