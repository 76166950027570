import { AppShell, Box, Container, Group, Tabs } from '@asuikit/core'
import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useSupportedChainId } from 'constants/chains'
import { useCurrency } from 'hooks/Tokens'
import useParsedQueryString from 'hooks/useParsedQueryString'
import { createContext, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { queryParametersToCurrencyState } from 'state/swap/hooks'
import { useLargeThan } from '../../hooks/detrade/useWidthQuery'

import { useDisclosure } from '@asuikit/hooks'
import { useRequest } from 'ahooks'
import { ProjectModel } from 'service/launch.api.d'
import { useToggleAccountDrawer } from '../../components/AccountDrawer/MiniPortfolio/hooks'
import { useConnectionReady } from '../../connection/eagerlyConnect'
import { useSwitchChain } from '../../hooks/useSwitchChain'
import { getProjectInfo } from '../../service/launch.api'
import LaunchInfo from './LaunchInfo'
import LaunchIntroduction from './LaunchIntroduction'
import LaunchPrivateSale from './LaunchPrivateSale'
import LaunchPublicSale from './LaunchPublicSale'

type LaunchDetailContextType = {
  refresh: () => void
}

export const LaunchDetailContext = createContext<LaunchDetailContextType>({
  refresh: () => undefined,
})

export default function LaunchDetailPage({ className }: { className?: string }) {
  const smScreen = useLargeThan('sm')
  const mdScreen = useLargeThan('md')
  const location = useLocation()

  const connectionReady = useConnectionReady()
  const { account, chainId: connectedChainId, connector } = useWeb3React()
  const supportedChainId = useSupportedChainId(connectedChainId)
  const chainId = supportedChainId || ChainId.ALIENXCHAIN

  const parsedQs = useParsedQueryString()
  const parsedCurrencyState = useMemo(() => {
    return queryParametersToCurrencyState(parsedQs)
  }, [parsedQs])

  const initialInputCurrency = useCurrency(parsedCurrencyState.inputCurrencyId, chainId)
  const initialOutputCurrency = useCurrency(parsedCurrencyState.outputCurrencyId, chainId)

  const [activeTab, setActiveTab] = useState<string | null>('pools')

  const switchChain = useSwitchChain()

  // toggle wallet when disconnected
  const toggleWalletDrawer = useToggleAccountDrawer()

  const [opened, { open, close }] = useDisclosure(false)
  const { launchId } = useParams<{
    launchId?: string
  }>()

  const {
    data: launchDetail = {} as ProjectModel,
    loading,
    refresh,
  } = useRequest(
    async () => {
      if (!launchId) return {} as ProjectModel
      const res = await getProjectInfo(launchId)
      return {
        ...res,
        project_distribution_time: res?.project_distribution_time && res?.project_distribution_time * 1000,
        project_end_time: res?.project_end_time && res?.project_end_time * 1000,
        project_start_time: res?.project_start_time && res?.project_start_time * 1000,
      }
    },
    {
      ready: !!launchId,
    }
  )
  return (
    <LaunchDetailContext.Provider
      value={{
        refresh,
      }}
    >
      <AppShell
        padding={0}
        // footer={<Footer fixed={false} />}
        styles={(theme) => ({
          main: {
            background: '#FFFFFF',
          },
        })}
      >
        <Box
          sx={({ colors }) => ({
            width: '100%',
            height: 180,
            backgroundImage: `url(${launchDetail.project_detail_banner})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'auto 100%',
            // borderRadius: 16,
            overflow: 'hidden',
            position: 'relative',
          })}
        ></Box>
        <Container mt={24} px={smScreen ? 172 : 16} size={1440}>
          <Group noWrap align="top" spacing={24}>
            <Box sx={{ flex: 1, overflowX: 'auto', overflowY: 'hidden' }}>
              <Tabs
                defaultValue="info"
                unstyled
                styles={({ colors }) => ({
                  tab: {
                    backgroundColor: '#F5F5F5',
                    color: '#808080',
                    borderRadius: 6,
                    padding: '8px 20px',
                    border: 0,
                    fontWeight: 500,
                    fontSize: 18,
                    lineHeight: '24px',
                    cursor: 'pointer',
                    '&[data-active]': {
                      backgroundColor: '#3C38F5',
                      color: '#FFFFFF',
                    },
                  },
                  tabsList: {
                    display: 'flex',
                    gap: '8px',
                    marginBottom: 24,
                  },
                })}
              >
                <Tabs.List>
                  <Tabs.Tab value="info">Info</Tabs.Tab>
                  {launchDetail.is_private_sale && <Tabs.Tab value="private">Private Sale</Tabs.Tab>}
                  <Tabs.Tab value="public">Public Sale</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="info">
                  <LaunchInfo item={launchDetail} loading={loading} />
                </Tabs.Panel>
                <Tabs.Panel value="private">
                  <LaunchPrivateSale item={launchDetail} loading={loading} />
                </Tabs.Panel>
                <Tabs.Panel value="public">
                  <LaunchPublicSale item={launchDetail} loading={loading} />
                </Tabs.Panel>
              </Tabs>
            </Box>
            <Box>
              <LaunchIntroduction item={launchDetail} loading={loading} />
            </Box>
          </Group>
        </Container>
      </AppShell>
    </LaunchDetailContext.Provider>
  )
}
