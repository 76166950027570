import { BigNumber } from '@ethersproject/bignumber'
import { Percent, Price, Token, V3_CORE_FACTORY_ADDRESSES } from '@uniswap/sdk-core'
import { Position } from '@uniswap/v3-sdk'
import RangeBadge from 'components/Badge/RangeBadge'
import { useToken } from 'hooks/Tokens'
import useIsTickAtLimit from 'hooks/useIsTickAtLimit'
import { PoolCache, usePool } from 'hooks/usePools'
import { Trans } from 'i18n'
import { useContext, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Bound } from 'state/mint/v3/actions'
import styled from 'styled-components'
import { MEDIA_WIDTHS } from 'theme'
import { ThemedText } from 'theme/components'
import { useFormatter } from 'utils/formatNumbers'
import { unwrappedToken } from 'utils/unwrappedToken'

import { Box, Button, Divider, Group, LoadingOverlay, Stack, Text, createStyles } from '@asuikit/core'
import { useRequest } from 'ahooks'
import { DoubleCurrencyLogo } from 'components/DoubleLogo'
import { getApiChainParam } from '../../constants/chains'
import { DAI, USDC_MAINNET, USDT, WBTC, WRAPPED_NATIVE_CURRENCY } from '../../constants/tokens'
import { StakeLiquidityModalContext } from '../../pages/StakePool/StakeLiquidityModal'
import { stakePoolChainId } from '../../pages/StakePool/stakePoolConst'
import { getStakeRewardInfo } from '../../service/pools.api'

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    root: {
      width: '100%',
      height: 640,
      overflow: 'auto',
      border: '1px solid ' + colors.line[1],
      background: colors.bg[1],
      borderRadius: 24,
      padding: '0 24px 24px',
      position: 'sticky',
      top: 96,
    },
    tag: {
      color: '#3C38F5',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      background: '#F1F1FE',
      padding: '2px 6px',
      borderRadius: 16,
    },

    label: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '16px',
      color: '#959AA4',
    },
    value: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '16px',
      color: '#4F5665',
    },
  }
})
const LinkRow = styled(Link)`
  align-items: center;
  display: flex;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${({ theme }) => theme.neutral1};
  padding: 16px;
  text-decoration: none;
  font-weight: 535;

  & > div:not(:first-child) {
    text-align: center;
  }

  :hover {
    background-color: ${({ theme }) => theme.deprecated_hoverDefault};
  }

  @media screen and (min-width: ${MEDIA_WIDTHS.deprecated_upToSmall}px) {
    /* flex-direction: row; */
  }

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex-direction: column;
    row-gap: 8px;
  `};
`

const DataLineItem = styled.div`
  font-size: 14px;
`

const RangeLineItem = styled(DataLineItem)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
  width: 100%;
`

const DoubleArrow = styled.span`
  font-size: 12px;
  margin: 0 2px;
  color: ${({ theme }) => theme.neutral1};
`

const RangeText = styled(ThemedText.BodySmall)`
  font-size: 14px !important;
  word-break: break-word;
  padding: 0.25rem 0.25rem;
  border-radius: 8px;
`

const FeeTierText = styled(ThemedText.UtilityBadge)`
  font-size: 16px !important;
  margin-left: 8px !important;
  color: ${({ theme }) => theme.neutral3};
`
const ExtentsText = styled(ThemedText.BodySmall)`
  color: ${({ theme }) => theme.neutral2};
  display: inline-block;
  line-height: 16px;
  margin-right: 4px !important;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    display: none;
  `};
`

const PrimaryPositionIdData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    margin-right: 8px;
  }
`

export interface PositionListItemProps {
  token0: string
  token1: string
  tokenId: BigNumber
  fee: number
  liquidity: BigNumber
  tickLower: number
  tickUpper: number
}

export function getPriceOrderingFromPositionForUI(position?: Position): {
  priceLower?: Price<Token, Token>
  priceUpper?: Price<Token, Token>
  quote?: Token
  base?: Token
} {
  if (!position) {
    return {}
  }

  const token0 = position.amount0.currency
  const token1 = position.amount1.currency

  // if token0 is a dollar-stable asset, set it as the quote token
  const stables = [DAI, USDC_MAINNET, USDT]
  if (stables.some((stable) => stable.equals(token0))) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    }
  }

  // if token1 is an ETH-/BTC-stable asset, set it as the base token
  const bases = [...Object.values(WRAPPED_NATIVE_CURRENCY), WBTC]
  if (bases.some((base) => base && base.equals(token1))) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    }
  }

  // if both prices are below 1, invert
  if (position.token0PriceUpper.lessThan(1)) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    }
  }

  // otherwise, just return the default
  return {
    priceLower: position.token0PriceLower,
    priceUpper: position.token0PriceUpper,
    quote: token1,
    base: token0,
  }
}

export function usePositionListItem(data: PositionListItemProps) {
  const {
    token0: token0Address,
    token1: token1Address,
    tokenId,
    fee: feeAmount,
    liquidity,
    tickLower,
    tickUpper,
  } = data

  const token0 = useToken(token0Address)
  const token1 = useToken(token1Address)

  const currency0 = token0 ? unwrappedToken(token0) : undefined
  const currency1 = token1 ? unwrappedToken(token1) : undefined

  // construct Position from details returned
  const [, pool] = usePool(currency0 ?? undefined, currency1 ?? undefined, feeAmount)

  const position = useMemo(() => {
    if (pool) {
      return new Position({ pool, liquidity: liquidity.toString(), tickLower, tickUpper })
    }
    return undefined
  }, [liquidity, pool, tickLower, tickUpper])

  const tickAtLimit = useIsTickAtLimit(feeAmount, tickLower, tickUpper)

  // prices
  const { priceLower, priceUpper, quote, base } = getPriceOrderingFromPositionForUI(position)

  const currencyQuote = quote && unwrappedToken(quote)
  const currencyBase = base && unwrappedToken(base)

  // check if price is within range
  const outOfRange: boolean = pool ? pool.tickCurrent < tickLower || pool.tickCurrent >= tickUpper : false

  const removed = liquidity?.eq(0)

  const inverted = token1 ? base?.equals(token1) : undefined

  return {
    tokenId,
    priceLower,
    priceUpper,
    currencyBase,
    currencyQuote,
    feeAmount,
    removed,
    outOfRange,
    tickAtLimit,
    inverted,
    position,
  }
}

export default function PositionListItem(props: PositionListItemProps) {
  const { formatDelta, formatTickPrice } = useFormatter()

  const { tokenId, priceLower, priceUpper, currencyBase, currencyQuote, feeAmount, removed, outOfRange, tickAtLimit } =
    usePositionListItem(props)
  const positionSummaryLink = '/pools/' + tokenId

  const { classes, cx } = useStyles()

  const navigate = useNavigate()

  const stakeLiquidityModalContext = useContext(StakeLiquidityModalContext)

  const token0 = useToken(props.token0)
  const token1 = useToken(props.token1)
  // const [poolState, pool, poolAddresses] = usePool(token0 ?? undefined, token1 ?? undefined, props.fee)
  const poolAddresses = useMemo(() => {
    if (token0 && token1 && feeAmount) {
      const tokenA = token0.wrapped
      const tokenB = token1.wrapped
      if (tokenA.equals(tokenB)) return undefined
      const params: [Token, Token, number] = tokenA.sortsBefore(tokenB)
        ? [tokenA, tokenB, feeAmount]
        : [tokenB, tokenA, feeAmount]
      return PoolCache.getPoolAddress(V3_CORE_FACTORY_ADDRESSES[stakePoolChainId], ...params)
    }
    return undefined
  }, [feeAmount, token0, token1])
  const { data: canStake } = useRequest(
    async () => {
      if (removed) return false
      if (!poolAddresses) return false
      const res = await getStakeRewardInfo({
        chain: getApiChainParam(stakePoolChainId),
        pool_address: poolAddresses,
      })
      return res?.length > 0
    },
    {
      refreshDeps: [poolAddresses],
    }
  )
  return (
    <>
      <Group
        spacing={8}
        py={18}
        pos="relative"
        sx={{ cursor: 'pointer' }}
        onClick={(e) => {
          navigate(positionSummaryLink)
        }}
      >
        <LoadingOverlay visible={!(priceLower && priceUpper)} overlayBlur={1} loaderProps={{ size: 20 }} />
        <DoubleCurrencyLogo currencies={[currencyBase, currencyQuote]} size={36} />

        <Stack spacing={4} sx={{ flex: 1 }}>
          <Group spacing={8} position="apart">
            <Group spacing={8}>
              <Text fw={500} fz={18} lh="24px" color="#0B132A">
                {currencyQuote?.symbol}&nbsp;/&nbsp;{currencyBase?.symbol}
              </Text>

              <Box className={classes.tag}>
                {formatDelta(parseFloat(new Percent(feeAmount, 1_000_000).toSignificant()))}
              </Box>
            </Group>
            <RangeBadge removed={removed} inRange={!outOfRange} />
          </Group>

          <Group spacing={10}>
            <Group spacing={6}>
              <Box className={classes.label}>Min:</Box>
              <Box className={classes.label}>
                {formatTickPrice({
                  price: priceLower,
                  atLimit: tickAtLimit,
                  direction: Bound.LOWER,
                })}{' '}
                <Trans>
                  {currencyQuote?.symbol} per {currencyBase?.symbol ?? ''}
                </Trans>
              </Box>
            </Group>
            <Divider orientation="vertical" h={10} sx={{ alignSelf: 'center' }} />

            <Group spacing={6}>
              <Box className={classes.label}>Max:</Box>
              <Box className={classes.label}>
                {formatTickPrice({
                  price: priceUpper,
                  atLimit: tickAtLimit,
                  direction: Bound.UPPER,
                })}{' '}
                <Trans>
                  {currencyQuote?.symbol} per {currencyBase?.symbol ?? ''}
                </Trans>
              </Box>
            </Group>
          </Group>
        </Stack>

        {canStake && (
          <Button
            size="sm"
            radius={36}
            variant="default"
            bg="#F1F1FE"
            sx={({ colors }) => ({
              border: 0,
              color: colors.blue[9],
            })}
            onClick={(e) => {
              e.stopPropagation()
              stakeLiquidityModalContext.setPool(props)
              stakeLiquidityModalContext.open()
            }}
          >
            Stake
          </Button>
        )}
      </Group>
    </>
  )
}
