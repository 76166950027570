import { Group, NumberInput, Popover, Stack, Text, Tooltip, createStyles } from '@asuikit/core'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RiAlertFill, RiErrorWarningFill, RiSettings2Fill } from 'react-icons/ri'
import { FeatureFlags } from 'uniswap/src/features/gating/flags'
import { useFeatureFlag } from 'uniswap/src/features/gating/hooks'
import { L2_CHAIN_IDS } from '../../../../constants/chains'
import { isUniswapXTrade } from '../../../../state/routing/utils'
import { useUserSlippageTolerance } from '../../../../state/user/hooks'
import { SlippageTolerance } from '../../../../state/user/types'
import { useFormatter } from '../../../../utils/formatNumbers'
import {
  MAXIMUM_RECOMMENDED_SLIPPAGE,
  MINIMUM_RECOMMENDED_SLIPPAGE,
} from '../../../../utils/validateUserSlippageTolerance'
import MaxSlippageSettings from '../../../Settings/MaxSlippageSettings'
import MultipleRoutingOptions from '../../../Settings/MultipleRoutingOptions'

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    root: {},
  }
})

export function getInputStyles({ colors }: any): any {
  return {
    root: {
      flex: 1,
    },
    wrapper: {
      height: 40,
      flex: 1,
    },
    input: {
      border: 0,
      background: colors.bg[2],
      borderRadius: 14,
      height: 40,
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '20px',
      color: '#4E5563',
      textAlign: 'right',
    },
  }
}

export function AlertWarn({ text }: any) {
  return (
    <Group spacing={6} noWrap align="top">
      <RiAlertFill size={16} color="#FE9839" />
      <Text fw={400} fz={12} lh="16px" color="#FE9839">
        {text}
      </Text>
    </Group>
  )
}

function SlippageSetting({ hideDeadline = true, trade, autoSlippage, chainId }: any) {
  const { classes, cx } = useStyles()
  const { t, i18n } = useTranslation()
  const [opened, setOpened] = useState(false)

  const [userSlippageTolerance] = useUserSlippageTolerance()
  const { formatPercent } = useFormatter()
  const hasWarning = useMemo(() => {
    return !(userSlippageTolerance === SlippageTolerance.Auto || isUniswapXTrade(trade))
  }, [trade, userSlippageTolerance])

  const tooLow =
    userSlippageTolerance !== SlippageTolerance.Auto && userSlippageTolerance.lessThan(MINIMUM_RECOMMENDED_SLIPPAGE)
  const tooHigh =
    userSlippageTolerance !== SlippageTolerance.Auto && userSlippageTolerance.greaterThan(MAXIMUM_RECOMMENDED_SLIPPAGE)
  const showDeadlineSettings = Boolean(chainId && !L2_CHAIN_IDS.includes(chainId))
  const multipleRoutingOptionsEnabled = useFeatureFlag(FeatureFlags.MultipleRoutingOptions)

  return (
    <div className={classes.root}>
      <Popover
        opened={opened}
        onChange={setOpened}
        width={371}
        withinPortal={true}
        position="bottom-start"
        styles={({ colors }) => ({
          dropdown: {
            border: '1px solid' + colors.line[1],
            background: '#FFFFFF',
            boxShadow: '0px 4px 70px 0px #0000001A',
            borderRadius: 12,
            padding: 16,
          },
        })}
      >
        <Popover.Target>
          <Group
            spacing={4}
            bg={tooLow || tooHigh ? '#FFF0E1' : '#F7F7F7'}
            sx={({ colors }) => ({
              borderRadius: 6,
              padding: '6px 8px',
            })}
            onClick={() => setOpened((o) => !o)}
          >
            <RiSettings2Fill size={20} color={tooLow || tooHigh ? '#FE9839' : '#4F5665'} />
            <Text fw={400} fz={16} lh="20px" color={tooLow || tooHigh ? '#FE9839' : '#4F5665'}>
              Slippage (
              {userSlippageTolerance === SlippageTolerance.Auto ? 'Auto' : formatPercent(userSlippageTolerance)})
            </Text>
          </Group>
        </Popover.Target>

        <Popover.Dropdown>
          <Stack spacing={28}>
            <MaxSlippageSettings autoSlippage={autoSlippage} />
            {showDeadlineSettings && (
              <Stack spacing={12}>
                <Group spacing={2}>
                  <Text fw={500} fz={16} lh="20px" color="#0B132A">
                    Transaction deadline
                  </Text>
                  <Tooltip
                    width={240}
                    withArrow
                    multiline
                    position="top-start"
                    label={
                      <Text fw={400} fz={14} lh="20px" color="grey.0">
                        Your transaction will be revert if it is pending for more than this long
                      </Text>
                    }
                  >
                    <Group>
                      <RiErrorWarningFill size={16} color="#BABDC4" />
                    </Group>
                  </Tooltip>
                </Group>

                <NumberInput
                  rightSectionWidth={100}
                  rightSection={
                    <Text fw={400} fz={16} lh="20px" color="#0B132A">
                      minutes
                    </Text>
                  }
                  styles={getInputStyles}
                />
                <AlertWarn text="Maximum time is 4,320 mintes" />
              </Stack>
            )}
            {multipleRoutingOptionsEnabled && (
              <>
                <MultipleRoutingOptions />
              </>
            )}
          </Stack>
        </Popover.Dropdown>
      </Popover>
    </div>
  )
}

export default SlippageSetting
